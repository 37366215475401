<template>
  <div class="divBox">
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
        <div style="width: 100%">
          <el-form-item label="订单状态：" prop="status">
            <el-radio-group
              v-model="tableFrom.status"
              type="button"
              @change="getList()"
            >
              <div style="display: flex">
                <el-radio-button
                  :label="i.value"
                  v-for="i in statusList"
                  :key="i.label"
                  >{{ i.label }}{{ i.num }}</el-radio-button
                >
              </div>
            </el-radio-group>
          </el-form-item>
        </div>

        <el-form-item label="单号：" prop="orderCode">
          <el-input
            v-model="tableFrom.orderCode"
            placeholder="请输入单号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="下单人：" prop="userName">
          <el-input
            v-model="tableFrom.userName"
            placeholder="请输入下单人"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="所属供应商" prop="vendorId">
          <el-select
            v-model="tableFrom.vendorId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="收货人：" prop="shippingName">
          <el-input
            v-model="tableFrom.shippingName"
            placeholder="请输入收货人："
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="电话：" prop="mobile">
          <el-input
            v-model="tableFrom.mobile"
            placeholder="请输入电话"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品名称：" prop="goodsName">
          <el-input
            v-model="tableFrom.goodsName"
            placeholder="请输入商品名称："
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
            class="input-with-select selWidth"
            v-model="tableFrom.time"
            value-format="yyyy/MM/dd"
            format="yyyy/MM/dd"
            size="small"
            type="daterange"
            placement="bottom-end"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 280px"
            :picker-options="pickerOptions"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="exportFun()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        :default-expand-all="true"
        class="table"
        row-key="id"
      >
        <el-table-column type="expand">
          <template slot-scope="orderItems">
            <div class="orderItems">
              <div
                v-for="(item, index) in orderItems.row.orderItems"
                class="item"
                :key="index"
              >
                <img class="item_img" :src="item.goodsSkuImg" alt="" />
                <div>
                  <div class="item_name">{{ item.goodsName }}</div>
                  <div class="item_price">售价：￥{{ item.salePrice }}</div>
                  <div class="item_num supplier">
                    供应商：{{ item.supplierName }}
                  </div>
                  <div class="item_num">规格：{{ item.goodsSkuName }}</div>
                  <div class="item_num">数量：x{{ item.quantity }}</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单号" prop="code" min-width="170" />
        <el-table-column label="用户昵称" prop="nickname" min-width="170" />
        <el-table-column label="订单金额" prop="orderAmount" min-width="170" />
        <el-table-column
          label="实付金额(元）"
          prop="payAmount"
          min-width="170"
        />
        <el-table-column 
          label="积分抵扣(元)"
          prop="scoreAmount"
          min-width="170"
        />
        <el-table-column label="使用积分" prop="score" min-width="170" />
        <el-table-column label="支付类型" min-width="170">
          <template slot-scope="scope">
            <span>{{ payTypes[scope.row.payType] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单时间" prop="orderTime" min-width="150" />
        <el-table-column label="订单状态" min-width="130">
          <template slot-scope="scope">
            <div>{{ state[scope.row.status] }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="收货人姓名"
          prop="shippingName"
          min-width="150"
        />
        <el-table-column
          label="收货人电话"
          prop="shippingMobile"
          min-width="150"
        />
        <el-table-column label="收货人地址" min-width="250">
          <template slot-scope="scope">
            <span>{{
              scope.row.shippingProvince +
              scope.row.shippingCity +
              scope.row.shippingDistrict +
              scope.row.shippingAddress
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-info"
              @click="onOrderDetails(scope.row.id)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <!--用户信息-->
  </div>
</template>
<script>
import { ordersSubPageList, ordersSubCount } from "@/api/order/order";
import { getSupplierListAll } from "@/api/supplier/supplier";
// import {ordersPageList} from "@/api/order/order";
import moment from "moment";
import { payTypes } from "../order/orders";
import timeOptions from "@/assets/utils/timeOptions"; 
import { exportExcel } from "@/assets/utils/exportExcel";
import { state } from "../order/orders";
export default {
  data() {
    return {
      state,
      payTypes,
      pickerOptions: timeOptions,
      drawer: false,
      orderId: 0,
      tableData: {
        total: 0,
      },
      listLoading: false,
      supplierList: [],
      statusList: [
        {
          label: "全部",
          num: 0,
          value: "",
        },
        {
          label: "待支付",
          num: 0,
          value: "0",
        },
        {
          label: "待发货",
          num: 0,
          value: "10",
        },
        {
          label: "部分发货",
          num: 0,
          value: "11",
        },
        {
          label: "待收货",
          num: 0,
          value: "20",
        },
        {
          label: "已完成",
          num: 0,
          value: "40",
        },
        {
          label: "已关闭",
          num: 0,
          value: "90",
        },
      ],
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        goodsName: "",
        mobile: "",
        orderCode: "",
        shippingName: "",
        vendorId: "",
        status: "",
        userName: "",
        time: [],
      },
    };
  },
  mounted() {
    this.getList();
    this.ordersNum();
    this.getSupplierList();
  },
  methods: {
    async ordersNum() {
      const res = await ordersSubCount();
      this.statusList[0].num = res.data["count"];
      this.statusList[1].num = res.data["count0"];
      this.statusList[2].num = res.data["count10"];
      this.statusList[3].num = res.data["count11"];
      this.statusList[4].num = res.data["count20"];
      // this.statusList[5].num = res.data["count30"];
      this.statusList[5].num = res.data["count40"];
      this.statusList[6].num = res.data["count90"];
    },
    exportFun() {
      exportExcel("/web/orders/subExport", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "供应商订单列表",
      });
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    /**重置 */
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
    closeDrawer() {
      this.drawer = false;
      this.userDawer = false;
    },
    changeDrawer(v) {
      this.drawer = v;
      this.userDawer = v;
    },
    /**订单列表 */
    downData(excelData) {
      return new Promise((resolve, reject) => {
        exportOrderApi(excelData).then((res) => {
          return resolve(res.data);
        });
      });
    },
    //获取供应商列表
    getSupplierList() {
      getSupplierListAll({}).then((res) => {
        this.supplierList = res.data;
      });
    },
    // 列表
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      ordersSubPageList({ ...this.tableFrom, time: "" })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    onOrderDetails(id) {
      this.$router.push({
        path: "/supplier/orderDetails",
        query: {
          id: id,
        },
      });
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/form.scss";
.check {
  color: #00a2d4;
}
.dia {
  /deep/ .el-dialog__body {
    height: 700px !important;
  }
}
.text-right {
  text-align: right;
}
.minwidth {
  display: inline-block;
  max-width: 200px;
  line-height: 20px;
}
.search-form {
  display: flex;
  justify-content: space-between;
  .search-form-box {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  a {
    color: var(--prev-color-primary);
  }
}
.selWidth {
  width: 250px !important;
}
.search-form-sub {
  display: flex;
}
.container {
  min-width: 821px;
  /deep/.el-form-item {
    width: 100%;
  }
  /deep/.el-form-item__content {
    width: 72%;
  }
}
.vipName {
  color: #dab176;
}
.el-dropdown-link {
  cursor: pointer;
  color: var(--prev-color-primary);
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}
/deep/.el-date-editor.el-input {
  width: 100%;
}
/deep/[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none !important;
}
/deep/.el-input-group__prepend .el-input {
  width: 100px;
}
.item {
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 10px;
  // align-items: center;
}
.item_img {
  width: 86rpx;
  height: 86px;
  margin-right: 10px;
  border-radius: 5px;
}
.supplier {
  font-weight: 600;
}
.item_name {
  font-weight: 500;
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}
.item_price {
  font-weight: 500;
  font-size: 12px;
  color: #e93323;
  margin-bottom: 5px;
}
.item_num {
  margin-bottom: 5px;
  font-size: 12px;
}
</style>
